import React from "react"

export const Clothing = () => {
  return (
    <svg width={20} viewBox="0 0 24 24">
      <path
        d="M16.14 1.55 20.5 3 18 10l1 13a.5.5 0 0 1-.5.52h-4.26a.51.51 0 0 1-.4-.2l-2.64-3.55a1 1 0 0 1-.2-.6v-4.76a1 1 0 0 1 .29-.7L12 13"
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M14.5.5c-.33 4-.83 8.5-2.5 12.5C10.33 9 9.83 4.5 9.5.5"
      />
      <path
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M9.5.5a5.24 5.24 0 0 0-2.8 2.59.5.5 0 0 0 .16.65L8 4.5l-.83 1.24a.53.53 0 0 0 0 .53A32.27 32.27 0 0 0 12 13a32.64 32.64 0 0 0 4.85-6.73.53.53 0 0 0 0-.53L16 4.5l1.14-.76a.5.5 0 0 0 .16-.65A5.24 5.24 0 0 0 14.5.5ZM13.5 15.25a.25.25 0 1 1-.25-.25.25.25 0 0 1 .25.25M13.49 18.25a.25.25 0 1 1-.25-.25.25.25 0 0 1 .25.25"
      />
      <path
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M7.85 1.55 3.5 3 6 10 5 23a.47.47 0 0 0 .14.37.51.51 0 0 0 .36.15h4.26a.51.51 0 0 0 .4-.2L12 20.84"
      />
      <path
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M3.5 3C2.11 9 .87 18.5.51 20.58a.5.5 0 0 0 .32.56L3 21.9a.49.49 0 0 0 .41 0 .51.51 0 0 0 .25-.34L6 10M20.5 3c1.39 6 2.63 15.5 3 17.58a.5.5 0 0 1-.32.56L21 21.9a.49.49 0 0 1-.41 0 .51.51 0 0 1-.25-.34L18 10"
      />
    </svg>
  )
}
