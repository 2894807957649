import React from "react"
import BackgroundImage from "gatsby-background-image"

export const Hero = ({ image }) => {
  return (
    <div
      id="hero"
      className="flex flex-col bg-white items-center justify-center"
    >
      <BackgroundImage
        className="w-full flex justify-center pt-32 lg:pt-36 pb-24"
        fluid={image}
        alt="Red rocks in sedona"
      >
        <div className="flex flex-col p-20 md:h-circle md:w-circle transition-opacity">
          <p className="text-center md:text-2xl text-base pb-8 mb-0 tracking-wide text-white animate-fade-in-down">
            April 26, 2022
          </p>
          <h1 className="leading-3 md:text-8xl text-6xl text-center animate-fade-in-down-long text-white">
            <span style={{ fontFeatureSettings: "'salt' 1" }}>A</span>licia
          </h1>
          <h1 className="md:text-8xl text-6xl md:-mt-4 -mb-4 mt-1 text-center text-primary-500">
            &
          </h1>
          <h1 className="md:text-8xl text-6xl text-center mb-4 animate-fade-in-up text-white">
            <span style={{ fontFeatureSettings: "'salt' 1" }}>D</span>illo
            <span style={{ fontFeatureSettings: "'salt' 1" }}>n</span>
          </h1>
          <p className="text-center md:text-2xl text-base pb-8 mb-0 tracking-wide text-white animate-fade-in-up">
            Sedona, Arizona
          </p>
        </div>
      </BackgroundImage>
    </div>
  )
}
