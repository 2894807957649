import React from "react"
import Img from "gatsby-image"

export const Accomodations = ({ image }) => {
  return (
    <div id="events" className="md:mr-12">
      <h2 className="text-4xl md:text-5xl md:mb-2 text-primary-500">
        <span style={{ fontFeatureSettings: "'salt' 1" }}>V</span>enue
      </h2>

      <p className="mb-16 md:leading-8 leading-7 text-black">
        We've rented out{" "}
        <a
          className="text-primary-500 transition-all duration-200 ease-in-out hover:underline"
          target="blank"
          href="https://www.redagaveresort.net/"
        >
          Red Agave Resort
        </a>{" "}
        in Sedona for two nights from Monday, April 25, 2022 to Wednesday, April
        27, 2022 and all wedding events will be held on property. We have a lot
        planned around celebrating our love but our wish is for you to be able
        to relax and enjoy your stay as well. Red Agave has a heated pool, 2 hot
        tubs and a hiking trail right off of the property to explore the magical
        Red Rocks.
      </p>

      <h2 className="text-4xl md:text-5xl md:mb-2 text-primary-500">
        <span style={{ fontFeatureSettings: "'salt' 1" }}>A</span>
        cc<span style={{ fontFeatureSettings: "'salt' 1" }}>o</span>
        modations
      </h2>

      <p className="mb-4 md:leading-8 leading-7 text-black">
        We request that everyone stays at Red Agave with us for 2 nights, Monday
        4/25 and Tuesday 4/26. You do not need to do anything to reserve your
        room – we’ve made it easy for you so you can just show up! We will be in
        touch with you to confirm your chalet or studio once you RSVP. Then we
        will provide your contact info to Red Agave and they will reach out to
        you to obtain your payment info to keep on file, but payment is not due
        until you check-in.
      </p>

      <h3>Check-in</h3>
      <p className="pb-4">4pm (feel free to come anytime before check-in)</p>

      <h3>Check-out</h3>
      <p className="pb-8">
        11am (feel free to hang at the pool after check-out)
      </p>

      <h4 className="text-lg md:text-2xl md:py-2">Room Options</h4>
      <ul className="mb-6 md:mb-10 md:leading-8 leading-7 text-black list-disc text-lg md:text-xl">
        <li>
          Chalets - sleeps up to 5, 1 king, and a loft with 1 queen and 1 twin:
          $279/night + tax
        </li>
        <li>Studios - sleeps up to 2, 1 king: $179/night + tax</li>
      </ul>

      <h4 className="text-lg md:text-2xl md:py-2">Transportation</h4>
      <p className="mb-6 md:mb-10 md:leading-8 leading-7 text-black">
        N/A - Since all events are held at Red Agave with everyone staying on
        property, there is no need for transportation to be provided for any
        wedding events.
      </p>

      <p className="mb-6 md:mb-10 md:leading-8 leading-7 text-black">
        Please don’t hesitate to reach out to us with any questions on your
        stay.
      </p>

      <div className="w-full">
        <Img fluid={image} />
      </div>
    </div>
  )
}
