import React from "react"

export const List = () => {
  return (
    <svg width={20} viewBox="0 0 16 16">
      <g
        fill="none"
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.138 3a.667.667 0 0 1 .195.471V15a.667.667 0 0 1-.666.667H2.333A.667.667 0 0 1 1.667 15V1a.667.667 0 0 1 .666-.667h8.862a.667.667 0 0 1 .472.196Z" />
        <path d="m4 5.18.722.722a.333.333 0 0 0 .507-.041l1.438-2.014M9 5h2.667M4 8.514l.722.721a.333.333 0 0 0 .507-.041l1.438-2.013M9 8.333h2.667M4 11.847l.722.722a.333.333 0 0 0 .507-.042l1.438-2.013M9 11.667h2.667" />
      </g>
    </svg>
  )
}
