import React from "react"
import Img from "gatsby-image"

export const Intro = ({ image }) => {
  return (
    <div className="max-w-2xl m-auto px-2 py-8 lg:py-12">
      <h1
        style={{ fontFeatureSettings: "'salt' 3" }}
        className="md:mt-6 md:text-4xl text-2xl text-center text-black max-w-md m-auto text-primary-500"
      >
        <span style={{ fontFeatureSettings: "'salt' 1" }}>H</span>i{" "}
        <span style={{ fontFeatureSettings: "'salt' 1" }}>F</span>riends & {""}
        <span style={{ fontFeatureSettings: "'salt' 1" }}>F</span>amily!
      </h1>
      <h1 className="md:text-4xl text-2xl text-center text-black pb-4 md:pb-8 max-w-md m-auto text-primary-500">
        We're getting married!
      </h1>
      <p className="mb-6 md:leading-8 leading-7 text-black">
        We've always imagined an intimate wedding where we can hang out and make
        the most of time spent together with our closest friends and family.
      </p>
      <p className="mb-6 md:leading-8 leading-7 text-black">
        Sedona, Arizona holds a special place in our hearts. It was the first
        road trip we ever went on together and remains our favorite getaway
        today, 8 years later. The energy and backdrop of Sedona make it the
        perfect spot for us to tie the knot.
      </p>
      <div className="w-full">
        <Img fluid={image} />
      </div>
    </div>
  )
}
