import React from "react"

export const Clock = () => {
  return (
    <svg width={20} viewBox="0 0 24 24">
      <g
        fill="none"
        stroke="#D16539"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.499 12.001a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0-23 0Z" />
        <path d="M12 6.501v5.5l6 5.5" />
      </g>
    </svg>
  )
}
