import React from "react"
import Img from "gatsby-image"

export const ImageGrid = ({ images }) => {
  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-1 grid-rows-auto">
      {images.map(({ node }) => (
        <Img
          key={node.base}
          fluid={node.childImageSharp.fluid}
          alt={node.base.split(".")[0]}
        />
      ))}
    </div>
  )
}
