import React from "react"
import { navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const YES_RESPONSE = "Yes, see you in Sedona!"
const NO_RESPONSE = "No, I'll be there in spirit."

export const Form = () => {
  const [state, setState] = React.useState({ attendance: YES_RESPONSE })

  const handleInputChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <form
      className="w-full bg-white px-6 py-8 shadow-md"
      name="rsvp"
      method="POST"
      action="/thanks"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col">
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="rsvp" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleInputChange} />
          </label>
        </p>
        <label
          className="text-xs uppercase tracking-widest pb-2 text-gray-600"
          htmlFor="name"
        >
          Guest Name(s) on invitation
        </label>
        <input
          required
          className="p-4 mb-8 bg-gray-100"
          placeholder="Enter name(s)"
          name="name"
          type="text"
          onChange={handleInputChange}
        />

        <label className="text-xs uppercase tracking-widest pb-4 text-gray-600">
          Your Response
        </label>

        <div className="flex flex-col mb-8">
          <label className="text-lg">
            <input
              type="radio"
              name="attendance"
              value={YES_RESPONSE}
              onChange={handleInputChange}
              className="mr-2 w-4 h-4 transform translate-y-0.5"
            />
            {YES_RESPONSE}
          </label>

          <label className="text-lg">
            <input
              type="radio"
              name="attendance"
              value={NO_RESPONSE}
              onChange={handleInputChange}
              className="mt-4 mr-2 w-4 h-4 transform translate-y-0.5"
            />
            {NO_RESPONSE}
          </label>
        </div>

        {state.attendance === YES_RESPONSE ? (
          <>
            <label
              className="text-xs uppercase tracking-widest pb-2 text-gray-600"
              htmlFor="dietary"
            >
              Any dietary restrictions?
            </label>
            <input
              className="p-4 mb-8 bg-gray-100"
              placeholder="Food item(s)"
              name="dietary"
              type="text"
              onChange={handleInputChange}
            />
            <label
              className="text-xs uppercase tracking-widest pb-2 text-gray-600"
              htmlFor="song"
            >
              What song(s) gets you dancing?
            </label>
            <input
              className="p-4 mb-8 bg-gray-100"
              placeholder="Song(s)"
              name="song"
              type="text"
              onChange={handleInputChange}
            />
            <label
              className="text-xs uppercase tracking-widest pb-2 text-gray-600"
              htmlFor="notes"
            >
              Anything else we need to know?
            </label>
            <textarea
              className="p-4 mb-8 bg-gray-100"
              name="notes"
              onChange={handleInputChange}
            />
            <label
              htmlFor="vaccination terms"
              className="text-xs uppercase tracking-widest pb-2 text-gray-600"
            >
              Covid-19
            </label>
            <p className="text-sm pb-4 text-gray-600">
              We want to make sure we all have a fun and safe time. We are
              requiring that everyone is fully vaccinated OR takes a PCR
              COVID-19 test within 72 hours of event, providing negative
              results.
            </p>
            <div className="flex items-center mb-8">
              <input
                required
                type="checkbox"
                className="mr-2 bg-gray-100"
                name="vaccination terms"
                onChange={handleInputChange}
              />
              <label
                className="text-xs uppercase tracking-widest text-gray-600"
                htmlFor="vaccination terms"
              >
                I agree to covid safety measures
              </label>
            </div>
          </>
        ) : null}
      </div>
      <button className="w-full bg-primary-500 text-white p-3" type="submit">
        Submit
      </button>
    </form>
  )
}
