import React from "react"
import { Clock, Clothing, List } from "../icons"

export const Events = () => {
  return (
    <div className="md:py-24 py-12 px-2 max-w-2xl m-auto">
      <h2 className="text-4xl md:text-5xl text-primary-500 pb-4 md:pb-12">
        Wedding Events
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div>
          <h3 className="text-black flex items-center md:mb-2">
            Monday
            <span className="text-primary-500 ml-2">4/25</span>
          </h3>
          <h2 className="text-primary-500 pb-4 md:pb-8">Welcome Party</h2>
          <div className="flex items-baseline md:pb-10 pb-4">
            <Clock />
            <div className="flex flex-col ml-2">
              <span className="text-primary-500">Time</span>
              <p className="text-black text-lg">6pm-9pm</p>
            </div>
          </div>
          <div className="flex items-baseline md:pb-10 pb-4">
            <Clothing />
            <div className="flex flex-col ml-2">
              <span className="text-primary-500">Attire</span>
              <p className="text-black text-lg ">Casual</p>
            </div>
          </div>
          <div className="flex items-baseline md:pb-10 pb-4">
            <div>
              <List />
            </div>

            <div className="flex flex-col ml-2">
              <span className="text-primary-500 pb-2">Details</span>
              <ul className="text-black text-lg list-disc">
                <li>Drinks & Rehearsal: 6:00pm</li>
                <li>Dinner: 6:30pm</li>
                <li>Fire pit, hot tub etc to follow</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-black flex items-center md:mb-2">
            Tuesday
            <span className="text-primary-500 ml-2">4/26</span>
          </h3>
          <h2 className="text-primary-500 pb-4 md:pb-8">The Big Day</h2>
          <div className="flex items-baseline md:pb-10 pb-4">
            <Clock />
            <div className="flex flex-col ml-2">
              <span className="text-primary-500">Time</span>
              <p className="text-black text-lg">4:30pm-10pm</p>
            </div>
          </div>
          <div className="flex items-baseline md:pb-10 pb-4">
            <Clothing />
            <div className="flex flex-col ml-2">
              <span className="text-primary-500">Attire</span>
              <p className="text-black text-lg ">Cocktail</p>
            </div>
          </div>
          <div className="flex items-baseline pb-4">
            <div>
              <List />
            </div>

            <div className="flex flex-col ml-2">
              <span className="text-primary-500 pb-2">Details</span>
              <ul className="text-black text-lg list-disc">
                <li>
                  Morning hike for those that want to join: time TBD - we will
                  confirm as it gets closer
                </li>
                <li>I Do's: 4:30pm</li>
                <li>Drinks, dinner & disco: 5-10pm</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
