import React from "react"
import { Form } from "./Form"

export const Rsvp = () => {
  return (
    <div id="rsvp" className="max-w-2xl m-auto pb-10 px-2">
      <h3 className="text-4xl md:text-5xl text-center md:mb-4 text-primary-500">
        RSVP
      </h3>
      <p className="md:leading-8 leading-7 text-black text-center">
        We can't express enough gratitude for your support leading up to this
        very exciting moment! It would be an honor to have you join us in
        celebrating our marriage. Please provide a response to the RSVP below by
        April 12, 2022.
      </p>

      <div className="flex items-center m-auto flex-col max-w-screen md:max-w-sm py-8">
        <Form />
      </div>
    </div>
  )
}
